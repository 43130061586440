const holidays = {
  holidays: [
    { name: "New Year’s Day", date: "January 1" },
    {
      name: "Martin Luther King’s Birthday",
      occurrence: "3rd Monday",
      month: "January",
    },
    {
      name: "Washington’s Birthday",
      occurrence: "3rd Monday",
      month: "February",
    },
    { name: "Memorial Day", occurrence: "last Monday", month: "May" },
    { name: "Juneteenth National Independence Day", date: "June 19" },
    { name: "Independence Day", date: "July 4" },
    { name: "Labor Day", occurrence: "1st Monday", month: "September" },
    { name: "Columbus Day", occurrence: "2nd Monday", month: "October" },
    { name: "Veterans’ Day", date: "November 11" },
    { name: "Thanksgiving Day", date: "November 28" },
    {
      name: "Thanksgiving Day",
      occurrence: "4th Thursday",
      month: "November",
    },
    { name: "Christmas Day", date: "December 25" },
  ],
};

export const isHolidayToday = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });
  const weekday = today.getDay(); // 0=Sunday, 1=Monday, ..., 6=Saturday

  return holidays.holidays.some((holiday) => {
    console.log(holiday.date);
    if (holiday.date) {
      // Exact date match
      const [holidayMonth, holidayDay] = holiday.date.split(" ");
      console.log(month === holidayMonth && day === parseInt(holidayDay));
      return month === holidayMonth && day === parseInt(holidayDay);
    }
    return false;
  });
};
