import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  DialogActions,
} from "@mui/material";
import { db, auth, functions } from "../config/firebase"; // Adjust the import according to your project structure
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
  where,
  Timestamp,
  getDoc,
  doc,
} from "firebase/firestore";
import { capitalizeFirstLetter } from "../utils/capitalize-first-letter-string";
import { queueMap } from "../utils/queue-map";
import { useAgent } from "../context/agent-context";
import { httpsCallable } from "firebase/functions";
import axios from "axios";

const LiveTransfersAndCallBacks = () => {
  const [transfers, setTransfers] = useState([]);
  const [callBacks, setCallBacks] = useState([]);
  const [page, setPage] = useState(0);
  const [callBacksPage, setCallBacksPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageCallBacks, setRowsPerPageCallBacks] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [lastVisibleCallBacks, setLastVisibleCallBacks] = useState(null);
  const [orderLiveTransfers, setOrderLiveTransfers] = useState("desc");
  const [orderCallBacks, setOrderCallBacks] = useState("desc");
  const [orderByLiveTransfers, setOrderByLiveTransfers] =
    useState("time_stamp");
  const [orderByCallBacks, setOrderByCallbacks] = useState("time_stamp");
  const [cachedCallBacks, setCachedCallBacks] = useState({});
  const [cachedLiveTransfers, setCachedLiveTransfers] = useState({});
  const [user, setUser] = useState(null); // Assuming you have a way to get the current user

  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);
  const [recordingUrls, setRecordingUrls] = useState([]);
  const [fetchingRecording, setFetchingRecording] = useState(false);

  const [callBackRecordingDialogOpen, setCallBackRecordingDialogOpen] =
    useState(false);
  const [callBackRecordingUrl, setCallBackRecordingUrl] = useState("");
  const [fetchingCallBackRecording, setFetchingCallBackRecording] =
    useState(false);

  const handleOpenCallBackRecordingDialog = async (callSid, agent) => {
    setFetchingCallBackRecording(true);
    setCallBackRecordingDialogOpen(true);

    try {
      const result = await axios.request({
        method: "POST",
        url: "https://gettwiliorecordingsfromcallsid-717635562383.us-central1.run.app",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          callSid,
          twilioAccountSid: agent.twilio_account_sid,
          twilioAuthToken: agent.twilio_auth_token,
        },
      });

      const urls = result.data;
      setCallBackRecordingUrl(urls);
    } catch (error) {
      console.error("Error fetching recording URLs:", error);
    } finally {
      setFetchingCallBackRecording(false);
    }
  };

  const handleCloseCallBackRecordingDialog = () => {
    setCallBackRecordingDialogOpen(false);
    setCallBackRecordingUrl("");
  };

  const handleOpenRecordingDialog = async (callSid, agent) => {
    setFetchingRecording(true);
    setRecordingDialogOpen(true);

    try {
      // console.log(`Using the following agent data:`);
      // console.dir(agent);

      const result = await axios.request({
        method: "POST",
        url: "https://gettwiliorecordingsfromcallsid-717635562383.us-central1.run.app",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          callSid,
          twilioAccountSid: agent.twilio_account_sid,
          twilioAuthToken: agent.twilio_auth_token,
        },
      });

      const urls = result.data;
      setRecordingUrls(urls);
    } catch (error) {
      console.error("Error fetching recording URLs:", error);
    } finally {
      setFetchingRecording(false);
    }
  };

  const handleCloseRecordingDialog = () => {
    setRecordingDialogOpen(false);
    setRecordingUrls([]);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  const fetchCallBacks = useCallback(
    async (page) => {
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      let agentDoc = agentSnapshot.docs[0];
      let agentId = localStorage.getItem("agentId");
      if (!agentId) {
        agentId = agentDoc.id;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentId", agentId);
      }

      if (agentId !== agentDoc.id) {
        let newAgentSnapshot = await getDoc(doc(db, "agents", agentId));
        agentDoc = newAgentSnapshot;
      }

      const callBacksRef = collection(db, `agents/${agentId}/lead_call_backs`);
      let q2;

      if (page > 0 && lastVisibleCallBacks) {
        q2 = query(
          callBacksRef,
          orderBy(orderByCallBacks, orderCallBacks),
          startAfter(lastVisibleCallBacks),
          limit(rowsPerPageCallBacks)
        );
      } else {
        q2 = query(
          callBacksRef,
          orderBy(orderByCallBacks, orderCallBacks),
          limit(rowsPerPageCallBacks)
        );
      }

      const querySnapshot2 = await getDocs(q2);
      const data2 = [];
      for (const _doc of querySnapshot2.docs) {
        let docData = _doc.data();
        let leadId = docData.lead_id;

        /**
         * Get the lead's call log document to get the recording URL
         */
        const leadCallLogsRef = query(
          collection(db, "agents", agentId, "leads", leadId, "call_logs"),
          orderBy("time_stamp", "desc")
        );

        /**
         * Get the lead's DATA
         */
        const leadRef = doc(db, "agents", agentId, "leads", leadId);
        const leadDoc = await getDoc(leadRef);

        // console.log(`Checking lead id ${leadId}`);
        // console.dir(leadDoc.data());

        if (!leadDoc.exists()) continue;

        const leadCallLogDoc = await getDocs(leadCallLogsRef);
        if (leadCallLogDoc.docs.length > 0) {
          const callLogData = leadCallLogDoc.docs[0].data();
          const callSid = callLogData.call_sid;

          // Get the recording URL
          const transcribedCallRef = query(
            collection(db, "agents", agentId, "transcribed_calls"),
            where("call_sid", "==", callSid)
          );
          const transcribedCallDoc = await getDocs(transcribedCallRef);
          const recordingUrl = !transcribedCallDoc.empty
            ? transcribedCallDoc.docs[0].data().recording_url
            : null;

          data2.push({
            id: _doc.id,
            // recording_url: recordingUrl,
            call_sid: callSid,
            first_name: capitalizeFirstLetter(leadDoc.data().first_name),
            lead_phone_number: leadDoc.data().phone_number,
            call_type: leadDoc.data().lead_type,
            agent: agentDoc.data(),
            ..._doc.data(),
          });
        } else {
          data2.push({
            id: _doc.id,
            call_sid: null,
            first_name: capitalizeFirstLetter(leadDoc.data().first_name),
            lead_phone_number: leadDoc.data().phone_number,
            call_type: leadDoc.data().lead_type,
            agent: agentDoc.data(),
            ..._doc.data(),
          });
        }
      }

      setCallBacks(data2);
      setLastVisibleCallBacks(
        querySnapshot2.docs[querySnapshot2.docs.length - 1]
      );

      // Cache the results
      setCachedCallBacks((prev) => ({
        ...prev,
        [page]: data2,
      }));
    },
    [
      user,
      orderByCallBacks,
      orderCallBacks,
      lastVisibleCallBacks,
      rowsPerPageCallBacks,
    ]
  );

  useEffect(() => {
    if (!cachedCallBacks[callBacksPage]) {
      fetchCallBacks(callBacksPage);
    } else {
      setCallBacks(cachedCallBacks[callBacksPage]);
    }
  }, [fetchCallBacks, callBacksPage, cachedCallBacks]);

  const fetchLiveTransfers = useCallback(
    async (_page) => {
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      let agentDoc = agentSnapshot.docs[0];
      let agentId = localStorage.getItem("agentId");
      if (!agentId) {
        agentId = agentDoc.id;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentId", agentId);
      }

      if (agentId !== agentDoc.id) {
        let newAgentSnapshot = await getDoc(doc(db, "agents", agentId));
        agentDoc = newAgentSnapshot;
      }

      const liveTransfersRef = collection(
        db,
        `agents/${agentId}/live_transfers`
      );

      let q2 = query(
        liveTransfersRef,
        orderBy(orderByLiveTransfers, orderLiveTransfers),
        startAfter(lastVisible),
        limit(rowsPerPage)
      );

      if (_page > 0 && lastVisible) {
        q2 = query(
          liveTransfersRef,
          orderBy(orderByLiveTransfers, orderLiveTransfers),
          startAfter(lastVisible),
          limit(rowsPerPage)
        );
      } else {
        q2 = query(
          liveTransfersRef,
          orderBy(orderByLiveTransfers, orderLiveTransfers),
          limit(rowsPerPage)
        );
      }

      let docsQ2 = await getDocs(q2);

      if (docsQ2.empty) return;

      const liveTransfersData = [];

      await Promise.all(
        docsQ2.docs.map(async (doc) => {
          if (!doc.exists) return;
          const docData = doc.data();
          const leadPhoneNumber = docData.lead_phone_number;

          // Get the lead data
          const leadQuery = query(
            collection(db, "agents", agentId, "leads"),
            where("phone_number", "==", leadPhoneNumber),
            limit(1)
          );

          const leadDoc = await getDocs(leadQuery);

          if (leadDoc.empty) return;

          // Get the latest call log from this lead
          const _callLogQuery = query(
            collection(
              db,
              "agents",
              agentId,
              "leads",
              leadDoc.docs[0].id,
              "call_logs"
            ),
            orderBy("time_stamp", "desc"),
            limit(1)
          );

          const callLogQuery = await getDocs(_callLogQuery);

          if (callLogQuery.docs.length > 0) {
            const callLogData = callLogQuery.docs[0].data();
            const callSid = callLogData.call_sid;

            // Get the recording URL
            const transcribedCallRef = query(
              collection(db, "agents", agentId, "transcribed_calls"),
              where("call_sid", "==", callSid)
            );
            const transcribedCallDoc = await getDocs(transcribedCallRef);
            const recordingUrl = !transcribedCallDoc.empty
              ? transcribedCallDoc.docs[0].data().recording_url
              : null;

            liveTransfersData.push({
              id: doc.id,
              recording_url: recordingUrl,
              lead_first_name: capitalizeFirstLetter(
                leadDoc.docs[0].data().first_name
              ),
              agent: agentDoc.data(),
              ...doc.data(),
            });
          } else {
            liveTransfersData.push({
              id: doc.id,
              lead_first_name: capitalizeFirstLetter(
                leadDoc.docs[0].data().first_name
              ),
              agent: agentDoc.data(),
              ...doc.data(),
            });
          }
        })
      );

      setTransfers(liveTransfersData);
      setLastVisible(docsQ2.docs[docsQ2.docs.length - 1]);

      // Cache the results
      setCachedLiveTransfers({
        ...cachedLiveTransfers,
        [page]: liveTransfersData,
      });
    },
    [user, orderByLiveTransfers, orderLiveTransfers, lastVisible, rowsPerPage]
  );

  useEffect(() => {
    if (!cachedLiveTransfers[page]) {
      fetchLiveTransfers(page);
    } else {
      setTransfers(cachedLiveTransfers[page]);
    }
  }, [fetchLiveTransfers, page, cachedLiveTransfers]);

  const handleChangeLiveTransferPage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeCallBackPage = async (event, newPage) => {
    setCallBacksPage(newPage);
  };

  // const handleChangeCallBackPage = async (event, newPage) => {
  //   setCallBacksPage(newPage);

  //   if (newPage > page) {
  //     const user = auth.currentUser;
  //     if (!user) return;

  //     const agentRef = collection(db, "agents");
  //     const agentQuery = query(agentRef, where("uid", "==", user.uid));
  //     const agentSnapshot = await getDocs(agentQuery);

  //     if (agentSnapshot.empty) return;

  //     const agentDoc = agentSnapshot.docs[0];
  //     const agentId = agentDoc.id;

  //     const callBacksRef = collection(db, `agents/${agentId}/lead_call_backs`);
  //     const q2 = query(
  //       callBacksRef,
  //       firestoreOrderBy(orderBy, order),
  //       startAfter(lastVisibleCallBacks),
  //       limit(rowsPerPage)
  //     );
  //     const querySnapshot2 = await getDocs(q2);
  //     const data2 = querySnapshot2.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setCallBacks(data2);
  //     setLastVisibleCallBacks(
  //       querySnapshot2.docs[querySnapshot2.docs.length - 1]
  //     );
  //   }
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRowsPerPageCallBacks = (event) => {
    setRowsPerPageCallBacks(parseInt(event.target.value, 10));
    setCallBacksPage(0);
    setLastVisibleCallBacks(null); // Reset last visible document for new page size
  };

  const handleRequestSort = (property) => {
    const isAsc =
      orderByLiveTransfers === property && orderLiveTransfers === "asc";
    setOrderLiveTransfers(isAsc ? "desc" : "asc");
    setOrderByLiveTransfers(property);
  };

  const handleRequestSortCallBacks = (property) => {
    const isAsc = orderByCallBacks === property && orderCallBacks === "asc";
    setOrderCallBacks(isAsc ? "desc" : "asc");
    setOrderByCallbacks(property);
  };

  return (
    <div
      className="wrapper"
      style={{
        marginRight: "3em",
      }}
    >
      <h1>Live transfers</h1>
      <Paper>
        <TableContainer
          style={{
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "agent_phone_number"}
                    direction={
                      orderByLiveTransfers === "agent_phone_number"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("agent_phone_number")}
                  >
                    Agent Phone Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "lead_first_name"}
                    direction={
                      orderByLiveTransfers === "lead_first_name"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("lead_first_name")}
                  >
                    Lead First Name
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "call_sid"}
                    direction={
                      orderByLiveTransfers === "call_sid"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("call_sid")}
                  >
                    Call SID
                  </TableSortLabel>
                </TableCell> */}
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "call_type"}
                    direction={
                      orderByLiveTransfers === "call_type"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("call_type")}
                  >
                    Call Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "lead_phone_number"}
                    direction={
                      orderByLiveTransfers === "lead_phone_number"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("lead_phone_number")}
                  >
                    Lead Phone Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "recording_url"}
                    direction={
                      orderByLiveTransfers === "recording_url"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("recording_url")}
                  >
                    Recording URL
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "time_stamp"}
                    direction={
                      orderByLiveTransfers === "time_stamp"
                        ? orderLiveTransfers
                        : "desc"
                    }
                    onClick={() => handleRequestSort("time_stamp")}
                  >
                    Time Stamp
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {transfers.map((transfer) => (
                <TableRow key={transfer.id}>
                  <TableCell>{transfer.agent_phone_number}</TableCell>
                  <TableCell>{transfer.lead_first_name}</TableCell>
                  {/* <TableCell>{transfer.call_sid}</TableCell> */}
                  <TableCell>{queueMap[transfer.call_type]}</TableCell>
                  <TableCell>{transfer.lead_phone_number}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        handleOpenRecordingDialog(
                          transfer.call_sid,
                          transfer.agent
                        )
                      }
                    >
                      <u>Open Recording</u>
                    </Button>
                  </TableCell>
                  <TableCell>
                    {new Date(
                      transfer.time_stamp.seconds * 1000
                    ).toLocaleString()}
                  </TableCell>
                  {/* <TableCell>
                    <a
                      href="#"
                      onClick={() => {
                        navigator.clipboard.writeText(transfer.call_sid);
                      }}
                    >
                      Copy call SID
                    </a>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1} // Unknown number of total rows
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={async (event, newPage) =>
            await handleChangeLiveTransferPage(event, newPage)
          }
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
      <br />
      <h1>Scheduled call backs</h1>
      <Paper>
        <TableContainer
          style={{
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "call_back_date_and_time"}
                    direction={
                      orderByCallBacks === "call_back_date_and_time"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() =>
                      handleRequestSortCallBacks("call_back_date_and_time")
                    }
                  >
                    Call back date and time
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "lead_first_name"}
                    direction={
                      orderByCallBacks === "lead_first_name"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() =>
                      handleRequestSortCallBacks("lead_first_name")
                    }
                  >
                    Lead first name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "lead_phone_number"}
                    direction={
                      orderByCallBacks === "lead_phone_number"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() =>
                      handleRequestSortCallBacks("lead_phone_number")
                    }
                  >
                    Lead phone number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "call_type"}
                    direction={
                      orderByCallBacks === "call_type" ? orderCallBacks : "asc"
                    }
                    onClick={() => handleRequestSortCallBacks("call_type")}
                  >
                    Call Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "raw_user_input"}
                    direction={
                      orderByCallBacks === "raw_user_input"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() => handleRequestSortCallBacks("raw_user_input")}
                  >
                    Call back transcription
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "recording_url"}
                    direction={
                      orderByCallBacks === "recording_url"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() => handleRequestSortCallBacks("recording_url")}
                  >
                    Recording URL
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "time_stamp"}
                    direction={
                      orderByCallBacks === "time_stamp" ? orderCallBacks : "asc"
                    }
                    onClick={() => handleRequestSortCallBacks("time_stamp")}
                  >
                    Time Stamp
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callBacks.map((callBack) => {
                return (
                  <TableRow key={callBack.id}>
                    <TableCell>{callBack.call_back_date_and_time}</TableCell>
                    <TableCell>{callBack.first_name}</TableCell>
                    <TableCell>{callBack.lead_phone_number}</TableCell>
                    <TableCell>{queueMap[callBack.call_type]}</TableCell>
                    <TableCell>{callBack.raw_user_input}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          handleOpenCallBackRecordingDialog(
                            callBack.call_sid,
                            callBack.agent
                          )
                        }
                      >
                        <u>Open Recording</u>
                      </Button>
                    </TableCell>
                    <TableCell>
                      {new Date(
                        callBack.time_stamp.seconds * 1000
                      ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1} // Unknown number of total rows
          rowsPerPage={rowsPerPageCallBacks}
          page={callBacksPage}
          onRowsPerPageChange={handleChangeRowsPerPageCallBacks}
          onPageChange={handleChangeCallBackPage}
        />
      </Paper>
      <Dialog
        open={callBackRecordingDialogOpen}
        onClose={handleCloseCallBackRecordingDialog}
      >
        <DialogTitle>Recording URL</DialogTitle>
        <DialogContent>
          {fetchingCallBackRecording ? (
            <CircularProgress />
          ) : (
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <a
                      href={callBackRecordingUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Call Recording
                    </a>
                  }
                />
              </ListItem>
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCallBackRecordingDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={recordingDialogOpen} onClose={handleCloseRecordingDialog}>
        <DialogTitle>Recording URLs</DialogTitle>
        <DialogContent>
          {fetchingRecording ? (
            <CircularProgress />
          ) : (
            <List>
              {recordingUrls.map((url, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {recordingUrls.length == 2
                          ? index == 1
                            ? "Voice AI call recording"
                            : "Recording after dial to agent"
                          : "Voice AI call recording"}
                      </a>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRecordingDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LiveTransfersAndCallBacks;
