import React, { useState, useEffect, useCallback } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db, auth } from "../config/firebase"; // Adjust the import based on your project structure
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const ManageLeads = () => {
  const [leads, setLeads] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstNameSearchQuery, setFirstNameSearchQuery] = useState("");
  const [lastNameSearchQuery, setLastNameSearchQuery] = useState("");
  const [phoneNumberSearchQuery, setPhoneNumberSearchQuery] = useState("");
  const [user, setUser] = useState(null); // Assuming you have a way to get the current user
  const [openDialog, setOpenDialog] = useState(false);
  const [leadToDelete, setLeadToDelete] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  const fetchLeads = useCallback(
    async (reset = false) => {
      const _limit = 8;

      if (!user) return;

      setLoading(true);
      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      let leadsQuery = query(
        collection(db, "agents", agentSnapshot.docs[0].id, "leads"),
        limit(_limit)
      );

      if (firstNameSearchQuery) {
        leadsQuery = query(
          collection(db, "agents", agentSnapshot.docs[0].id, "leads"),
          where("first_name", "==", firstNameSearchQuery.toUpperCase()),
          limit(_limit)
        );
      } else if (lastNameSearchQuery) {
        leadsQuery = query(
          collection(db, "agents", agentSnapshot.docs[0].id, "leads"),
          where("last_name", "==", lastNameSearchQuery.toUpperCase()),
          limit(_limit)
        );
      } else if (phoneNumberSearchQuery) {
        leadsQuery = query(
          collection(db, "agents", agentSnapshot.docs[0].id, "leads"),
          where("phone_number", "==", phoneNumberSearchQuery),
          limit(_limit)
        );
      }

      if (lastVisible && !reset) {
        leadsQuery = query(leadsQuery, startAfter(lastVisible));
      }

      const agentLeadQuerySnapshot = await getDocs(leadsQuery);
      const newLeads = agentLeadQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setLeads((prevLeads) => (reset ? newLeads : [...prevLeads, ...newLeads]));
      setLastVisible(
        agentLeadQuerySnapshot.docs[agentLeadQuerySnapshot.docs.length - 1]
      );
      setLoading(false);
    },
    [
      user,
      lastVisible,
      firstNameSearchQuery,
      lastNameSearchQuery,
      phoneNumberSearchQuery,
    ]
  );

  useEffect(() => {
    fetchLeads(true);
  }, [fetchLeads]);

  const handleDelete = (lead) => {
    setLeadToDelete(lead);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    if (!user || !leadToDelete) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);

    await deleteDoc(
      doc(db, "agents", agentSnapshot.docs[0].id, "leads", leadToDelete.id)
    );
    setLeads((prevLeads) =>
      prevLeads.filter((lead) => lead.id !== leadToDelete.id)
    );
    setOpenDialog(false);
    setLeadToDelete(null);
  };

  const handleFirstNameSearch = (e) => {
    setFirstNameSearchQuery(e.target.value);
  };
  const handleLastNameSearch = (e) => {
    setLastNameSearchQuery(e.target.value);
  };
  const handlePhoneNumberSearch = (e) => {
    setPhoneNumberSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchLeads(true);
  };

  const handleNextPage = () => {
    fetchLeads();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setLeadToDelete(null);
  };

  return (
    <div
      className="wrapper"
      style={{
        marginRight: "3em",
      }}
    >
      <Container>
        <div style={{ height: "75px" }} />
        <Typography variant="h4" align="center" gutterBottom>
          Manage Leads
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Welcome to the "Manage Leads" tab. Here, you can view and consult all
          currently active leads in your account. To view specific leads you
          want to consult, please begin by typing their first name, last name,
          or phone number to pull up the specific leads you would like to check
          data on. If you need to check other leads or access all of them,
          please use your Agent Autopilot account (
          <a href="https://automatesales.app/">https://automatesales.app/</a>)
        </Typography>
        <div
          style={{
            height: "4em",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "20px",
          }}
        >
          <form
            onSubmit={handleSearchSubmit}
            style={{ marginBottom: "20px", width: "250px" }}
          >
            <TextField
              label="Search by first name"
              variant="outlined"
              fullWidth
              value={firstNameSearchQuery}
              onChange={handleFirstNameSearch}
            />
          </form>
          <form
            onSubmit={handleSearchSubmit}
            style={{ marginBottom: "20px", width: "250px" }}
          >
            <TextField
              label="Search by last name"
              variant="outlined"
              fullWidth
              value={lastNameSearchQuery}
              onChange={handleLastNameSearch}
            />
          </form>
          <form
            onSubmit={handleSearchSubmit}
            style={{ marginBottom: "20px", width: "250px" }}
          >
            <TextField
              label="Search by phone number"
              variant="outlined"
              fullWidth
              value={phoneNumberSearchQuery}
              onChange={handlePhoneNumberSearch}
            />
          </form>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.map((lead) => (
                <TableRow key={lead.id}>
                  <TableCell>{lead.first_name}</TableCell>
                  <TableCell>{lead.last_name}</TableCell>
                  <TableCell>{lead.phone_number}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(lead)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this lead?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default ManageLeads;
