import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export function AgentDetailsDialog({
  agentDetailsDialogOpen,
  handleCloseAgentDetailsDialog,
  loadingAgentDetails,
  agentDetails,
}) {
  return (
    <Dialog
      open={agentDetailsDialogOpen}
      onClose={handleCloseAgentDetailsDialog}
    >
      <DialogTitle>Agent Details</DialogTitle>
      <DialogContent>
        {loadingAgentDetails ? (
          <CircularProgress />
        ) : agentDetails ? (
          <div>
            <Typography variant="h6">Name: {agentDetails.name}</Typography>
            <Typography variant="h6">Email: {agentDetails.email}</Typography>
            <Typography variant="h6">Phone: {agentDetails.phone}</Typography>
            {/* Add more fields as needed */}
          </div>
        ) : (
          <Typography variant="h6">No details available</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAgentDetailsDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
